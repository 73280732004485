.intro {
    background-image: url("./ypsa.png");
    height: 110vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 5rem;
}

.hey {
    color: white;
    margin-top: 5rem;
    z-index: 3;
    text-align: center;
    padding-top: 20vh;
    padding-bottom: 70vh;
}

.container {
    font-size: 24px;
    color: white;
    font-weight: medium;
    text-align: center;
    padding: 2rem;
    z-index: 3;
    box-shadow: 0 1px 20px rgba(0, 0, 0, 0.75);
    background-color: #1364af;
    border-radius: 8px;
    align-items: center;
    display: inline-flex;
    justify-content: space-between;
}

.container h1 {
    color:white;
    z-index: 3;
    text-align: center;
    padding-top: 5vh;
    padding-bottom: 5vh;
}

.container img {
    width: 80vw;
    height: 50vh;
}

.hero {
    padding-top: 25vh;
    text-align: center;
    display:flex;
    margin: 3rem;
    margin-top: 0;
    margin-bottom: 50px;
}

.contact button {
    background-color: white;
    padding: 6px 20px;
    border-radius: 12px;
    border-color: transparent;
    color:#1364af;
    font-weight: bold;
}

.contact button:hover {
    background-color: rgb(194, 194, 194);
    cursor: pointer;
}

@media (max-width: 900px) {
    .intro {
        background-image: url('./ypsaMedia.png');
    }
    .hero {
        padding-top: 15vh;
    }
    .container {
        font-size: 15px;
        color: white;
        text-align: center;
        padding: 1rem;
        z-index: 3;
        box-shadow: 0 1px 20px rgba(0, 0, 0, 0.75);
        background-color: #1364af;
        border-radius: 8px;
        align-items: center;
        display: block;
        justify-content: space-between;
    }
    .container h1 {
        color:white;
        z-index: 3;
        text-align: center;
        padding-top: 1vh;
        padding-bottom: 1vh;
    }
    .container img { 
        width: 100%;
        height: auto;
    }
}