.title {
  position: fixed;
  top: 0;
  left: 0;
  width: 90%;
  height: 10%;
  background-color: #0059AB;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25); */
  z-index: 6;
}

.icons ul {
    list-style: none;
    display: inline-flex;
  }
  
.icons li {
    padding: 20px;
}


@media (max-width: 900px) {
  .title {
    height: 10%;
  }
}

/*   
.head { 
    background: none;
    color:rgba(255, 255, 255, s0.98);
    border: none;
    font-size: 2rem;
    -ms-flex-align: stretch;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
} */
