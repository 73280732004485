.ftr {
  margin-top: auto;
  background-color: #1364af;
  text-align: center;
  color: white;
  font-weight: 700;
  height: 9vh;
  font-size: 24px;
  position: sticky;
}

.ftr p {
    margin: 0;
}

.ftr ul {
    margin: 0;
    display: inline-flex;
    list-style: none;
    padding: 0;
}

.links button {
    text-decoration: underline;
    display: inline-flex;
    background: none;
    border:none;
    align-items: center;
    height: 100%;
    cursor: pointer;
    color: white;
  }

.links button:hover {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.00);
    transition: 0.3s;
    animation: slide-right 300ms ease-in forwards;
}

@keyframes slide-right {
    from {
      opacity: 0;
      transform: translateX(0rem);
    }
    to {
      opacity: 1;
      transform: translateX(0rem);
    }
  } 