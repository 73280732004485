.hero {
    padding: 1rem;
    box-shadow: 0 1px 20px rgba(0, 0, 0, 0.75);
    background-color: #1364af;
    border-radius: 8px;
    align-items: center;
    display: inline-flex;
    padding: 25px;
    gap: 3rem;
    justify-content: space-between;
}