.submit {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.submit section {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0.5vh;
}

.submit label {
    padding: 3vh;
    color: white;
    font-weight: 500;
}

.submit input {
    border: none;
    border: solid 1px #ccc;
    border-radius: 7px;
}

.submit textarea {
    border-radius: 7px;
    color: black;
}

.submit select {
    border-radius: 7px;
    color: black;
}

.submit button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 30px;
    border-radius: 8px;
    background-color: aliceblue;
    border-color: transparent;
    margin-top: 40px;
    cursor: pointer;
    box-shadow: 2px 2px 10px black;
}

.submit button:hover {
    background-color: rgb(167, 179, 189);
}


@media (max-width: 900px) {
    .submit textarea {
        width: 15em;
        height: 5vh;
    }

    .submit section{
        display: flex;
        flex-direction: column;
    }

    .submit input {
        width: 15em;
        height: 1.5em;
    }
    
    .submit label {
        padding: 2vh;
    }

    .submit section {
        padding: 0vh;
    }
}
