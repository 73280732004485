.intro {
    margin-top: 5.5rem;
    text-align: center;
    align-items: center;
    color: #1364af;
}
    
.intro img {
    width: 80%;
}

.people ul {
    list-style: none;
    columns: 3 20vh;
    padding-left: 0;
    margin: 0rem;
    margin-top: 3rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.people li {
    margin: 0;
    margin-top: 0;
    margin-bottom: 3rem; 
    text-align: center;
    padding: 0 30px;
}

.information {
    color: white;
    box-shadow: 0 1px 20px rgba(0, 0, 0, 0.75);
    background-color: #1364af;
    border-radius: 20px;
    width: 45vh;
    height: 70vh;
    justify-content: s5pace-between;
}

.information p {
    text-align: justify;
    margin: 20px;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 18px;
    font-weight: 500;
}

.information h2 {
    font-size: 24px;
    font-weight: 700;
}

.information h3 {
    font-size: 20px;
    font-weight: 600;
}

.information img {
    margin-top: 25px;
    width: 60%;
    border: 5px solid white ;
    border-radius: 100px;
}


@media (max-width: 900px) {
    .intro {
        height: auto;
    }
    .intro img {
        width: 100%;
    }
    .people ul{
        display: flex;
        flex-direction: column;
        padding: 0;
        align-items: center;
        columns: 1;
        margin: 0;
        margin-top: 2rem;
    }
    .people li {
        padding: 0;
    }
    .information {
        height: auto;
    }
    .information h2 {
        font-size: 30px;
    }
    .information h3 {
        font-size: 24px;
        font-weight: 650;
    }
    .information p {
        font-size: 20px;
        padding-bottom: 2rem;
    }
    .information img {
        width: 40%;
    }
}