.button button {
  display: inline-flex;
  background: none;
  border:none;
  align-items: center;
  height: 100%;
  cursor: pointer;
  }

.button button:hover {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.00);
    transition: 0.3s;
    animation: slide-right 300ms ease-in forwards;
}


  @keyframes slide-right {
    from {
      opacity: 0;
      transform: translateX(0rem);
    }
    to {
      opacity: 1;
      transform: translateX(0rem);
    }
  } 