.page {
    color: #1364AF;
    text-align: center;
    margin-top: 12vh;
}

@media (max-width: 900px) {
    .page {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}